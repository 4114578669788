import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-privacy-goyoung',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './page-privacy-goyoung.component.html',
  styleUrl: './page-privacy-goyoung.component.less'
})
export class PagePrivacyGoyoungComponent {

  constructor(
    private titleService: Title,
  ) {
    //Page Title 設定
    this.titleService.setTitle('隱私權政策 - GOYOUNG');
  }

}
