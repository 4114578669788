import { MtIconElmModule } from './mt-icon-elm/mt-icon-elm.module';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactUsService } from './service/contact-us.service';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingAscentComponent } from './common/uni-loading/loading-ascent/loading-ascent.component';
// import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
// import { environment } from '../environments/environment';
import { LocalOnlyGuard } from './local-only.guard';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    NgxJsonLdModule,
    NoopAnimationsModule,
    LoadingAscentComponent,
    BrowserModule,
    MtIconElmModule,
    BreadcrumbComponent,
    // ServiceWorkerModule.register('ngsw-worker.js'),
    // BrowserAnimationsModule,
  ],
  providers: [
    LocalOnlyGuard,
    ContactUsService,
    Title,
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({ enabled: environment.production }),
    // }
  ],

  bootstrap: [AppComponent],

})
export class AppModule { }
