import { Component } from '@angular/core';

@Component({
  selector: 'logo-ind-dark',
  standalone: true,
  imports: [],
  templateUrl: './logo-ind-dark.component.html',
  styleUrl: './logo-ind-dark.component.less'
})
export class LogoIndDarkComponent {

}
