import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtIconMenuComponent } from './mt-icon-menu/mt-icon-menu.component';
import { MtIconCheckComponent } from './mt-icon-check/mt-icon-check.component';
import { MtIconDownloadComponent } from './mt-icon-download/mt-icon-download.component';
import { MtIconChevronRightComponent } from './mt-icon-chevron-right/mt-icon-chevron-right.component';
import { MtIconExpandMoreComponent } from './mt-icon-expand-more/mt-icon-expand-more.component';
import { MtIconCloseComponent } from './mt-icon-close/mt-icon-close.component';
import { MtIconExpandLessComponent } from './mt-icon-expand-less/mt-icon-expand-less.component';
import { MtIconDocComponent } from './mt-icon-doc/mt-icon-doc.component';
import { MtIconVideoComponent } from './mt-icon-video/mt-icon-video.component';
import { MtIconLeftComponent } from './mt-icon-left/mt-icon-left.component';
import { MtIconRightComponent } from './mt-icon-right/mt-icon-right.component';



@NgModule({
  declarations: [
    MtIconMenuComponent,
    MtIconCheckComponent,
    MtIconDownloadComponent,
    MtIconChevronRightComponent,
    MtIconExpandMoreComponent,
    MtIconCloseComponent,
    MtIconExpandLessComponent,
    MtIconDocComponent,
    MtIconVideoComponent,
    MtIconLeftComponent,
    MtIconRightComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MtIconMenuComponent,
    MtIconCheckComponent,
    MtIconDownloadComponent,
    MtIconChevronRightComponent,
    MtIconExpandMoreComponent,
    MtIconCloseComponent,
    MtIconExpandLessComponent,
    MtIconDocComponent,
    MtIconVideoComponent,
  ]
})
export class MtIconElmModule { }
