
//關鍵字設定
export const metaKeywords = {
  group_A: '雋揚國際, 雋揚建築, ASCENT DEVELOPMENT, 台灣建商, 台灣房地產, 不動產, 營建業, 建設, 國揚建設, 國揚集團, 國揚相關企業, 高雄全家海神, 中和羊毛, 中和羊毛股份有限公司',
}

export const filesPath = {
  ascentURL: 'https://www.ascentglobal.com.tw/',
  reportPath: 'download/report/',
  recordPath: 'download/record/',
  noticePath: 'download/notice/',
  notePath: 'download/note/',
  procedurePath: 'download/procedure/',
  casePhotoPath: 'photo/case/',
}

export const errorPath = {
  pageTitle: '找不到頁面',
  pageType: 'error',
}

export const soonPath = {
  pageTitle: '頁面施工中',
  pageType: 'soon',
}

export const lang = {
  langTW: {
    code: 'tw',
    sub: '繁體中文',
  },
  langEN: {
    code: 'en',
    sub: '英文',
  },
}

export const AppPathSetting = {

  //繁體中文首頁
  home_TW: {
    order: 0,
    path: 'tw',
    name_CH: '首頁',
    name_EN: '',
    pageTitle: '雋揚建築 Ascent',
    pageType: 'home',
  },

  //英文
  home_EN: {
    order: 1,
    path: 'en',
    name_CH: '首頁',
    name_EN: '',
    pageTitle: 'Ascent Global',
    pageType: 'home',
  },


  //首頁
  home: {
    order: 0,
    path: '',
    name_CH: '首頁',
    name_EN: '',
    pageTitle: '雋揚國際股份有限公司 ASCENT DEVELOPMENT',
    pageType: 'home',
    meta_descp: '本公司隸屬-國揚集團。國揚集團，自1972年成創立以來，以不動產建築營造、百貨商場、觀光飯店、複合式巨蛋興建等建築發跡,從無到有、從有到多、從多到精緻，奠定品牌精神。本公司原為羊毛產業上游廠商(中和羊毛工業股份有限公司)，成立50年來均以產品品質優良，股票上市20餘年， 營運狀況穩健著稱 ; 於2019年加入國揚集團，2022年6月份調整營運方向轉型為土地開發建設產業，持續為股東創造更大的收益，同時提供員工更多元的工作發展。',
    meta_keyword: metaKeywords.group_A,
  },

  about: {
    order: 1,
    path: 'about',
    name_CH: '關於雋揚',
    name_EN: 'About',
    pageType: 'landingPage',
    meta_descp: '雋典恆揚，與時建築。雋，「永恆光芒」，象徵溫暖的能量原點與時間力量。揚，「乘光而揚」，在時光的長河之上展翼遨翔。雋揚建築，與世界共鳴共好之路，共築永恆建築，不斷向上進化。',
    meta_keyword: metaKeywords.group_A,

    children: {
      intro: {
        order: 0,
        path: null,
        fragment: 'intro',
        name_CH: '公司理念',
        name_EN: 'intro',
        pageType: 'page',
        icon: '',
      },
      philosophy: {
        order: 0,
        path: null,
        fragment: 'philosophy',
        name_CH: '五恆為一',
        name_EN: 'Philosophy',
        pageType: 'page',
        icon: '',
      },
      history: {
        order: 1,
        path: null,
        fragment: 'history',
        name_CH: '公司沿革',
        name_EN: 'History',
        pageType: 'page',
        icon: '',
      },
      team: {
        order: 2,
        path: null,
        fragment: 'team',
        name_CH: '經營團隊',
        name_EN: 'Team',
        pageType: 'page',
        icon: '',
      },
      organization: {
        order: 3,
        path: null,
        fragment: 'organization',
        name_CH: '組織架構',
        name_EN: 'Organization',
        pageType: 'page',
        icon: '',
      },
      featured: {
        order: 4,
        path: null,
        fragment: 'featured',
        name_CH: '精選消息',
        name_EN: 'Featured',
        pageType: 'page',
        icon: '',
      },
      affiliate: {
        order: 5,
        path: null,
        fragment: 'affiliate',
        name_CH: '關係企業',
        name_EN: 'Affiliate',
        pageType: 'page',
        icon: '',
      },
    }
  },

  showcase: {
    order: 2,
    path: 'showcase',
    name_CH: '作品鑑賞',
    name_EN: 'Showcase',
    pageType: 'landingPage',
    meta_descp: '雋揚國際, 雋揚建築, 建案, 廠辦, 商辦, 住宅, 危老重建, 都更, 國揚集團子公司',
    meta_keyword: metaKeywords.group_A,

    children: {
      detail: {
        order: 0,
        path: 'detail/:id',
        name_CH: '詳細資訊',
        name_EN: '',
        pageType: 'detail',
        icon: '',
      },
    }
  },

  investor: {
    order: 3,
    path: 'investor',
    name_CH: '投資人專區',
    name_EN: 'Investor',
    pageType: 'fullPage',
    meta_descp: '雋揚建築, 投資人專區, 投資人資訊, 財務資訊, 股東服務, 股東會資訊',
    meta_keyword: metaKeywords.group_A,

    children: {

      stock: {
        order: 0,
        path: 'IR',
        name_CH: '投資人資訊',
        name_EN: 'IR',
        pageType: 'page',
        icon: 'ascent_use_icon_investor_stock',
      },

      finance: {
        order: 1,
        path: 'finance',
        name_CH: '財務資訊',
        name_EN: 'Finance',
        pageType: 'page',
        icon: 'ascent_use_icon_investor_finance',
      },

      service: {
        order: 2,
        path: 'service',
        name_CH: '股東服務',
        name_EN: 'Service',
        pageType: 'page',
        icon: 'ascent_use_icon_investor_service',
      },

      majorStockholder: {
        order: 3,
        path: 'major',
        name_CH: '主要股東',
        name_EN: 'Major',
        pageType: 'page',
        icon: 'ascent_use_icon_investor_major',
      },

      stockholder: {
        order: 4,
        path: 'shareholders',
        name_CH: '股東會資訊',
        name_EN: 'Shareholders',
        pageType: 'page',
        icon: 'ascent_use_icon_investor_CInfo',
      },

    }

  },

  governance: {
    order: 4,
    path: 'governance',
    name_CH: '公司治理',
    name_EN: 'Governance',
    pageType: 'page',
    meta_descp: '雋揚建築, 公司治理, 利害關係人, 永續發展',
    meta_keyword: metaKeywords.group_A,

    children: {

      policies: {
        order: 0,
        path: 'policies',
        name_CH: '公司治理',
        name_EN: 'Policies',
        pageType: 'page',
        icon: 'ascent_use_icon_gover_policies',
      },

      stakeholder: {
        order: 1,
        path: 'stakeholder',
        name_CH: '利害關係人',
        name_EN: 'Stakeholder',
        pageType: 'page',
        icon: 'ascent_use_icon_gover_stakeholder',
      },

      csr: {
        order: 2,
        path: 'csr',
        name_CH: '永續發展',
        name_EN: 'CSR',
        pageType: 'page',
        icon: 'ascent_use_icon_gover_envir',
      }

    }

  },

  urban: {
    order: 5,
    path: 'urban',
    name_CH: '都更專區',
    name_EN: 'Urban Renewal',
    pageType: 'page',
    meta_descp: '雋揚國際, 雋揚建築, 都更專區, 都更資訊',
    meta_keyword: metaKeywords.group_A,

    children: {
      detail: {
        order: 0,
        path: 'detail/:id',
        name_CH: '詳細資訊',
        name_EN: '',
        pageType: 'detail',
        icon: '',
      },
    }
  },

  contact: {
    order: 6,
    path: 'contact',
    name_CH: '聯絡我們',
    name_EN: 'Contact us',
    pageType: 'page',
    meta_descp: '雋揚國際, 雋揚建築, 聯繫資訊',
    meta_keyword: metaKeywords.group_A,

    children: {
      contactInfo: {
        order: 0,
        path: 'info',
        name_CH: '聯絡資訊',
        name_EN: 'Contact Information',
        pageType: 'page',
        icon: 'ascent_use_icon_contact_info',
      },
      contactForm: {
        order: 1,
        path: 'form',
        name_CH: '服務信箱',
        name_EN: 'Service Mail',
        pageType: 'page',
        icon: 'ascent_use_icon_contact_form',
      },
    }
  },

  member: {
    order: 7,
    path: 'member',
    name_CH: '會員中心',
    name_EN: 'Member Center',
    pageType: 'page',
    meta_descp: '雋揚國際, 雋揚建築, 會員中心',
    meta_keyword: metaKeywords.group_A,

    children: {
      account: {
        order: 0,
        path: 'account',
        name_CH: '帳戶資訊',
        name_EN: 'Account',
        pageType: 'page',
        icon: 'ascent_use_icon_member_account',
      },
      constructionInfo: {
        order: 1,
        path: 'info',
        name_CH: '建案資訊',
        name_EN: 'Construction',
        pageType: 'page',
        icon: 'ascent_use_icon_member_constrInfo',
      },
      constructionRepair: {
        order: 2,
        path: 'repair',
        name_CH: '修繕專區',
        name_EN: 'Repair',
        pageType: 'page',
        icon: 'ascent_use_icon_member_constrRep',
      },

    }
  },

  privacy: {
    order: 8,
    path: 'privacy',
    name_CH: '隱私權政策',
    name_EN: 'Privacy Policy',
    pageType: 'page',
    meta_descp: '雋揚國際隱私權政策',
  }

}


export const AppPathParam = {
  caseId: ':caseId',
}

