import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PageStatusService {

  //localPath
  private localPath = new BehaviorSubject<string>('');

  get_LocalPath_Status() {
    return this.localPath.asObservable();
  }

  update_LocalPath_Status(status: string) {
    this.localPath.next(status);
  }


  //navLinePos
  private navLinePos = new BehaviorSubject<number>(0);

  get_navLinePos_Status() {
    return this.navLinePos.asObservable();
  }

  update_navLinePos_Status(status: number) {
    this.navLinePos.next(status);
  }


  //RtPath
  private RtPath = new BehaviorSubject<string>('');

  get_RtPath_Status() {
    return this.RtPath.asObservable();
  }

  update_RtPath_Status(status: string) {
    this.RtPath.next(status);
  }


  // pageType
  private pageType = new BehaviorSubject<string>('');

  get_pageType_Status() {
    return this.pageType.asObservable();
  }

  update_pageType_Status(status: string) {
    this.pageType.next(status);
  }

  // kvTitle_CH
  private kvTitle_CH = new BehaviorSubject<string>('');

  get_kvTitle_CH_Status() {
    return this.kvTitle_CH.asObservable();
  }

  update_kvTitle_CH_Status(status: string) {
    this.kvTitle_CH.next(status);
  }



  // kvTitle_EN
  private kvTitle_EN = new BehaviorSubject<string>('');

  get_kvTitle_EN_Status() {
    return this.kvTitle_EN.asObservable();
  }

  update_kvTitle_EN_Status(status: string) {
    this.kvTitle_EN.next(status);
  }


  // caseName
  private caseName = new BehaviorSubject<string>('');

  get_caseName_Status() {
    return this.caseName.asObservable();
  }

  update_caseName_Status(status: string) {
    this.caseName.next(status);
  }

  // detail Page Name 麵包屑針對詳細資料取得id Name
  private detailPageName = new BehaviorSubject<string>('');

  get_detailPageName() {
    return this.detailPageName.asObservable();
  }

  update_detailPageName(status: string) {
    this.detailPageName.next(status);
  }

  //Detail Page Urban Id
  private detailPageUrbanId = new BehaviorSubject<string>('');

  get_detailPageUrbanId() {
    return this.detailPageUrbanId.asObservable();
  }

  update_detailPageUrbanId(status: string) {
    this.detailPageUrbanId.next(status);
  }



  constructor() { }
}
