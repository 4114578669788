import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactUs } from '../models/contact-us.model';


@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private api = environment.ApiUrl;
  
  constructor(
    private http: HttpClient,
  ) { }

  sendForm(contactUs: ContactUs) {
    const method: string = 'ContactUs/SendMail';

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(this.api + method, contactUs, options);
  }

  postMsg(input: any) {
    // return this.http.post(this.api, input, { responseType: 'Text' }).pipe(
    //   map(
    //     (response: any) => {
    //       if (response) {
    //         return response;
    //       }
    //     },
    //     (error: any) => {
    //       return error;
    //     }
    //   )
    // )
  }



}
