<loading-ascent [isLoading]="setLoading"></loading-ascent>
<ngx-json-ld [json]="schema"></ngx-json-ld>

<main
  [ngClass]="{'desktopMode': deviceInfo == 'desktop', 'tabletMode': deviceInfo == 'tablet', 'mobileMode': deviceInfo == 'mobile'}">

  <uni-breadcrumb></uni-breadcrumb>
  <router-outlet></router-outlet>
  <div id="goTop" (click)="onActivate($event)" [ngClass]="{'show': goTopStatus == true}">
    <app-mt-icon-expand-less [setColor]="'#FFF'" [setSize]="'30'"></app-mt-icon-expand-less>
  </div>

  @if (updateAvailable) {
    <div style="display: none;">
      New update available. Please refresh the page.
    </div>
  }

  <div style="display: none;">20230412</div>

</main>