// import { PageSoonComponent } from './main-page/page-soon/page-soon.component';
import { PageErrorComponent } from './main-page/page-error/page-error.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { lang } from './util/app-path-set';
import { PagePrivacyGoyoungComponent } from './main-page/page-privacy-goyoung/page-privacy-goyoung.component';




const routes: Routes = [
  {
    path: '',
    redirectTo: lang.langTW.code,
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./main-lang/main-lang.module').then(m => m.MainLangModule),
  },
  {
    path:'privacy-policy/goyoung',
    component: PagePrivacyGoyoungComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageErrorComponent,
  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
