import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, NavigationStart, NavigationCancel } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { PageStatusService } from 'src/app/service/page-status.service';
import { Subscription } from 'rxjs';
import { lang } from 'src/app/util/app-path-set';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

interface BreadcrumbInterface {
  label: string;
  params: Params;
  url: string;
}

@Component({
  standalone: true,
  selector: 'uni-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less'],
  imports: [
    CommonModule,
    RouterModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: BreadcrumbInterface[];


  showSpinnerSubject$ = new BehaviorSubject<boolean>(false);
  showSpinnerAction$ = this.showSpinnerSubject$.asObservable();
  navigationEvent = this.router.events;
  navigationEventEnd = this.navigationEvent.pipe(
    filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
    tap(() => this.showSpinnerSubject$.next(false)),
    tap(() => { this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root); })
  );


  navigationEventStart = this.navigationEvent.pipe(
    filter(event => event instanceof NavigationStart),
    tap(() => this.showSpinnerSubject$.next(true)),
  );

  getSubscription: Subscription = new Subscription;
  pageType: string = '';
  getLang: any;
  setbreadLang!: string;
  RtPath!: string;
  localStorLangKey: string = 'LangKey';
  deviceInfo: any = this.deviceService.getDeviceInfo().deviceType;


  detailPageName$!: Observable<string>;
  pageType$!: Observable<string>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private pageStatusService: PageStatusService,
    private deviceService: DeviceDetectorService,
  ) {
    this.breadcrumbs = [];

  }

  ngOnInit(): void {
    // this.breadcrumbs = this.getBreadcrumbs(this.router.routerState.root);

    this.detailPageName$ = this.pageStatusService.get_detailPageName();
    this.pageType$ = this.pageStatusService.get_pageType_Status();

    this.getSubscription = this.pageStatusService.get_pageType_Status().subscribe((pageType: string) => this.pageType = pageType);
    this.getSubscription = this.pageStatusService.get_RtPath_Status().subscribe((RtPath: string) => this.RtPath = RtPath);
    // this.getSubscription = this.langService.getLangSet().subscribe((langSet: any) => this.getLang = langSet);

    this.navigationEventStart.subscribe();
    this.navigationEventEnd.subscribe();

  }

  ngOnDestroy() {

  }



  private getBreadcrumbs(
    route: ActivatedRoute, url: string = '',
    breadcrumbs: BreadcrumbInterface[] = []): BreadcrumbInterface[] {

    this.getLang = localStorage.getItem(this.localStorLangKey);


    if (this.getLang == lang.langEN.code) {
      this.setbreadLang = 'breadcrumb_EN';
    } else {
      this.setbreadLang = 'breadcrumb';
    }

    const children: ActivatedRoute[] = route.children;


    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty(this.setbreadLang)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      url += `/${routeURL}`;

      const breadcrumb: BreadcrumbInterface = {
        label: child.snapshot.data[this.setbreadLang],
        params: child.snapshot.params,
        url
      };



      if (breadcrumb.label) {
        breadcrumbs.push(breadcrumb);
      }

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }


  backClicked() {
    this.location.back();
  }


  goFullScreen() {
    (document.querySelector('#main') as HTMLElement).requestFullscreen().then();
  }

}
