import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LoadTimerService {

  //整體內容讀取狀態
  private loadStatus = new BehaviorSubject<boolean>(true);

  getStatus() {
    return this.loadStatus.asObservable();
  }

  updateStatus(status: boolean) {
    this.loadStatus.next(status);
  }


  //關於我們 - 內容滾動讀取的狀態
  private pageAboutContendLoadStatus = new BehaviorSubject<boolean>(false);

  getPageAboutContLoadStatus() {
    return this.pageAboutContendLoadStatus.asObservable();
  }
  updatePageAboutContLoadStatus(status: boolean) {
    this.pageAboutContendLoadStatus.next(status);
  }




  constructor() { }
}
