import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppPathSetting, errorPath } from 'src/app/util/app-path-set';
import { KeyVisualDataService } from 'src/app/service/key-visual-data.service';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.less']
})
export class PageErrorComponent implements OnInit {


  private pageTitle = errorPath.pageTitle;


  //localStorage Set
  localStorPageKey: string = 'pageKey';
  getLocalPath: string = errorPath.pageType;


  constructor(
    private titleService: Title,
    private kvDataService: KeyVisualDataService,
  ) {
    //Page Title 設定
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.localStorageProcess();
    this.kvDataService.setPageType(this.getLocalPath);
    this.kvDataService.setRtPath(this.getLocalPath);
  }

  localStorageProcess() {
    localStorage.removeItem('pageKey');
    localStorage.setItem(this.localStorPageKey, this.getLocalPath);
  }

}
