import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mt-icon-expand-less',
  templateUrl: './mt-icon-expand-less.component.html',
  styleUrls: ['./mt-icon-expand-less.component.less']
})
export class MtIconExpandLessComponent {
  @Input() setColor!: string;
  @Input() setSize!: string;
}
