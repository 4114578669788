<div id="errorPage" class="errorPage">

    <div class="mainCont">
        <div class="Content">
            <div class="Topic">404</div>
            <p class="en">Page Not Found</p>
            <p class="ch">抱歉...找不到頁面喔!</p>
            <div class="footer">
                <a href="./" target="_self">
                    <img src="./assets/images/logo_build_light.svg" alt="雋揚建築">
                </a>
            </div>
        </div>

    </div>

</div>
