import { Component, HostListener, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AppPathSetting } from './util/app-path-set';
import { NavDataService } from './service/nav-data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { SwUpdate } from '@angular/service-worker';
import { LoadTimerService } from './service/load-timer.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  kvTitle_CH: string = '';
  kvTitle_EN: string = '';
  pageTitle = AppPathSetting.home.pageTitle;
  goTopStatus: boolean = false;
  navDisplay: boolean = true;
  deviceInfo: any = this.deviceService.getDeviceInfo().deviceType;
  setLoading: boolean = true;
  updateAvailable: boolean = false;

  //SEO
  meta_descp: string = AppPathSetting.home.meta_descp;
  meta_keywords: string = AppPathSetting.home.meta_keyword;

  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: '雋揚國際股份有限公司 ASCENT DEVELOPMENT',
    url: 'https://www.ascentglobal.com.tw',
    description: this.meta_descp,
  };

  @HostListener("window:scroll", ["$event", "{passive: true}"])
  onWindowScroll() {

    const getNavHidePos = document.getElementById("getNavHide")?.offsetTop;

    if (window.scrollY > 150) {
      this.goTopStatus = true;
    }
    else {
      this.goTopStatus = false;
    }

    if (window.scrollY > Number(getNavHidePos)) {
      this.nvDT.setHideNav(this.navDisplay = false);
    } else {
      this.nvDT.setHideNav(this.navDisplay = true);
    }

  }

  constructor(
    private titleService: Title,
    private nvDT: NavDataService,
    private deviceService: DeviceDetectorService,
    private metaTagService: Meta,
    // private swUpdate: SwUpdate,
    private loadTimerService: LoadTimerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.setLoadingTime();
    this.updateLoadStatus();
    this.addMetaTag();
    // this.checkSW();
    setTimeout(() => {
      this.loadGoogleFontsCross();
      this.loadGoogleFontsPrefetch();
      this.loadGoogleFonts();
    }, 2400);
  }

  // checkSW() {
  //   if (this.swUpdate.isEnabled) {
  //     this.swUpdate.available.subscribe(() => {
  //       this.updateAvailable = true;
  //       this.swUpdate.activateUpdate().then(() => {
  //         document.location.reload();
  //       });
  //     });
  //   }
  // }

  addMetaTag() {
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content: this.meta_keywords,
      },
      {
        name: 'description',
        content: this.meta_descp,
      },
    ]);
  }

  loadGoogleFontsCross() {
    const link = this.document.createElement('link');
    link.rel = 'preconnect';
    link.href = 'https://fonts.gstatic.com';
    link.crossOrigin = ''; // 添加 crossorigin 屬性
    this.document.head.appendChild(link);
  }
  loadGoogleFontsPrefetch() {
    const link = this.document.createElement('link');
    link.rel = 'dns-prefetch';
    link.href = 'https://fonts.gstatic.com';
    this.document.head.appendChild(link);
  }
  loadGoogleFonts() {
    const link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500&display=swap';
    this.document.head.appendChild(link);
  }

  loadMT_ICON() {
    const link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';
    this.document.head.appendChild(link);
  }

  onActivate(event: Event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  setLoadingTime() {
    setTimeout(() => {
      this.setLoading = false;
    }, 2550);
  }

  updateLoadStatus() {
    setTimeout(() => {
      this.loadTimerService.updateStatus(false);
    }, 2500);
  }


}
