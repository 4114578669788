import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NavDataService {

  private hideNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  public setHideNav(hideNav: boolean): void {
    this.hideNav$.next(hideNav);
  }

  public getHideNav(): Observable<boolean> {
    return this.hideNav$.asObservable();
  }






}
