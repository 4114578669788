
import { Component, Input } from '@angular/core';
import { LogoIndDarkComponent } from '../../ascent-logo/logo-ind-dark/logo-ind-dark.component';

@Component({
  standalone: true,
  selector: 'loading-ascent',
  templateUrl: './loading-ascent.component.html',
  styleUrls: ['./loading-ascent.component.less'],
  imports: [
    LogoIndDarkComponent,
  ]
})
export class LoadingAscentComponent {

  @Input() isLoading!: boolean;

}
