<svg version="1.1" id="ascentLogo-ind-dark" x="0px" y="0px" viewBox="0 0 400 400"
    style="enable-background:new 0 0 400 400;" xml:space="preserve">
    <g>
        <g>
            <polygon class="st0" points="78.5,244.6 167.4,244.6 236.2,138.5 236.2,26.9 		" />
            <polygon class="st1" points="311.6,40.6 311.6,231.6 321.5,244.6 321.5,26.9 		" />
            <polygon class="st2" points="303.6,221.2 303.6,51.6 293.2,66 293.2,207.6 		" />
            <polygon class="st3" points="285.3,197.1 285.3,77 276.4,89.2 276.4,185.5 		" />
            <polygon class="st4" points="268.4,175.1 268.4,100.2 259.1,113 259.1,162.9 		" />
            <polygon class="st0" points="251.2,124 240.6,138.6 251.2,152.5 		" />
            <polygon class="st5" points="311.6,244.6 321.5,244.6 311.6,231.6 		" />
            <polygon class="st5" points="293.2,207.6 293.2,244.6 303.6,244.6 303.6,221.2 		" />
            <polygon class="st5" points="276.4,185.5 276.4,244.6 285.3,244.6 285.3,197.1 		" />
            <polygon class="st5" points="259.1,162.9 259.1,244.6 268.4,244.6 268.4,175.1 		" />
            <polygon class="st5" points="240.6,138.6 240.6,244.6 251.2,244.6 251.2,152.5 		" />
        </g>
        <g>
            <g>
                <path class="st6" d="M81.6,307.8c0-6.9,4.6-10.8,14-10.8H106v-4.4c0-7.9-4.4-8.8-11.1-8.9c-3,0-6.1,0.4-9,0.8v-1.9
				c3-0.6,6.3-1.2,10.6-1.2c9,0,15,2.3,15,11.4v25.6H106V316c-2.3,1.5-6,2.8-10.9,2.8C85.8,318.8,81.6,314.4,81.6,307.8z M106,314.8
				v-15.9h-9c-7.6,0-9.6,2.5-9.6,8.7c0,6.4,2.1,9,9.2,9C100.9,316.5,104.5,315.4,106,314.8z" />
                <path class="st6" d="M125.5,317.6v-1.9c3.8,0.5,6.9,0.7,11.1,0.7c8.3,0,12.1-2.2,12.2-6.8c0.1-2.2-0.7-3.5-2.4-4.6
				c-1.7-1.2-4.3-1.9-8-2.9c-3.8-1-7-1.9-9-3.6c-2-1.6-3.2-3.7-3.2-7c0-8.3,7.5-10.2,13.4-10.2c4.3,0,7.8,0.5,10.7,1.1v1.9
				c-3-0.5-6.2-0.7-9.2-0.7c-6.8,0-9.9,1.9-9.9,6.2c0,2.4,0.8,3.8,2.4,4.8c1.7,1.1,4.2,1.8,7.4,2.6c4.3,1.1,7.5,2.1,9.6,3.6
				c2.2,1.6,3.3,3.8,3.3,7.3c-0.2,7.8-7.5,10.5-15.9,10.5C132.5,318.7,129.2,318.3,125.5,317.6z" />
                <path class="st6" d="M166.7,300.1c0-12.4,6.5-18.8,18.9-18.8c3.5,0,6.5,0.5,8.9,0.9v1.9c-2.4-0.3-4.8-0.5-7.2-0.5
				c-10.6,0-14.7,4.4-14.7,16.3c0,12,4,16.5,14.7,16.5c2.4,0,5.2-0.2,7.5-0.5v1.9c-2.4,0.5-5.7,0.9-9.2,0.9
				C173.1,318.7,166.7,312.2,166.7,300.1z" />
                <path class="st6" d="M205.9,300c0-12.2,6.9-18.8,16-18.8c9.4,0,15.9,6,15.9,18.3c0,0.4,0,1-0.2,1.5h-25.9
				c0.2,12.3,3.9,15.3,14.5,15.3c3.2,0,6.1-0.2,9.3-0.9v1.9c-3.1,0.9-6.4,1.3-10.8,1.3C213.8,318.7,205.9,314.6,205.9,300z
				 M211.7,299H232c-0.2-10.5-2.3-15.5-10-15.5C214.4,283.5,212,288.7,211.7,299z" />
                <path class="st6" d="M253,281.7h5.5v2.5c2.7-1.5,7.1-2.9,11.7-2.9c8.7,0,13.7,3.6,13.7,13.6v23.5h-5.6v-23.4c0-8.2-3-11-10.6-11
				c-3.6,0-6.3,0.7-9,1.6v32.8H253V281.7z" />
                <path class="st6" d="M296.7,281.7h5.8V271h5.6v10.7h10.3v2.2h-10.3v26.2c0,4.6,1.9,6.3,6.2,6.3c1.6,0,2.6-0.2,4.1-0.5v1.9
				c-1.7,0.5-3.3,0.8-6.2,0.8c-5.4,0-9.6-2-9.6-10v-24.7h-5.8V281.7z" />
            </g>
            <g id="雋揚建築_00000116225498593013665900000009036814880403152024_">
                <g>
                    <polygon class="st6" points="157.3,365.9 161.8,356.7 165.9,356.7 161.4,365.9 				" />
                    <polygon class="st6" points="170.1,371.9 175.3,360.9 179.4,360.9 174.2,371.9 				" />
                    <polygon class="st6" points="162.3,371.9 167.5,360.9 171.6,360.9 166.4,371.9 				" />
                    <polygon class="st6" points="147.2,357.2 158.3,352.7 158.3,356.5 147.2,361 				" />
                    <path class="st6" d="M178.2,371.9v-3.4h3.6v-5.9h-20.1v-2.8h21.8c1.9,0,2.2,0.3,2.2,2.2v7.5c0,1.9-0.3,2.2-2.2,2.2L178.2,371.9
					L178.2,371.9z" />
                    <path class="st6"
                        d="M147.7,371.9v-3.4h4.1v-27.1h3.8v26.9c0,2-1.6,3.5-3.5,3.5L147.7,371.9L147.7,371.9z" />
                    <polygon class="st6" points="146,349 147.4,346.2 159.9,346.2 159.9,349 				" />
                    <path class="st6" d="M162.5,353.1V343c0-0.8,0.6-1.5,1.5-1.5h19.4c0.8,0,1.5,0.6,1.5,1.5v10.1
					C184.9,353.1,162.5,353.1,162.5,353.1z M164.1,352h18.4v-9.1h-18.4V352z" />
                    <polygon class="st6" points="163.3,353.1 164.3,350.4 182.5,350.4 182.5,353.1 				" />
                    <polygon class="st6" points="163.3,348.5 164.3,346 182.5,346 182.5,348.5 				" />
                    <polygon class="st6" points="163.3,344 164.3,341.5 182.5,341.5 182.5,344 				" />
                    <rect x="162.5" y="342.9" class="st6" width="3.2" height="9.1" />
                    <rect x="181.7" y="342.9" class="st6" width="2.1" height="9.1" />
                    <polygon class="st6" points="158.2,357.9 159.6,355.3 184.9,355.3 184.9,357.9 				" />
                    <rect x="162.5" y="342.8" class="st6" width="3.8" height="8.8" />
                    <polygon class="st6" points="181,351.6 181,342.3 184.9,343.4 184.9,351.6 				" />
                </g>
                <g>
                    <polygon class="st6" points="89.3,345 90.7,342.4 116.6,342.4 116.6,345 				" />
                    <polygon class="st6" points="81.5,348.1 85.2,340.9 87.5,340.9 87.5,348.1 				" />
                    <polygon class="st6" points="89.3,349.5 90.7,347 116.6,347 116.6,349.5 				" />
                    <polygon class="st6" points="89.3,354.1 90.7,351.6 116.6,351.6 116.6,354.1 				" />
                    <polygon class="st6" points="89.3,358.7 90.7,356.2 116.6,356.2 116.6,358.7 				" />
                    <polygon class="st6" points="83.9,358.7 83.9,346.8 86.4,341 88.1,341 88.1,358.7 				" />
                    <rect x="100.6" y="340.4" class="st6" width="4.5" height="16" />
                    <path class="st6"
                        d="M95.9,368.4c-2,0-2.2-0.3-2.2-2.2v-5.1H98v4.3h4.6v-4.3h4.4v5.1c0,2-0.3,2.2-2.2,2.2H95.9z" />
                    <path class="st6" d="M84,371.9v-7.3c0-2,1.6-3.5,3.5-3.5h10.3v3.3h-10v7.6L84,371.9L84,371.9z" />
                    <path class="st6" d="M109.1,371.9v-3.4h3.6v-4.3h-10.1V361h10.4c2,0,3.5,1.6,3.5,3.5v3.8c0,2-1.6,3.5-3.5,3.5L109.1,371.9
					L109.1,371.9z" />
                </g>
                <g>
                    <polygon class="st6" points="310.7,359.7 310.7,351 314.7,352 314.7,359.7 				" />
                    <polygon class="st6" points="313.1,359.7 313.6,358.1 317.6,358.1 317.6,359.7 				" />
                    <path class="st6"
                        d="M312.1,355.6v-4.1h-5.4v-1.2h6.4c0.9,0,1.5,0.6,1.5,1.3v4L312.1,355.6L312.1,355.6z" />
                    <rect x="311" y="352.1" class="st6" width="3.7" height="6.8" />
                    <polygon class="st6" points="298.7,351.9 299.7,350.3 311,350.3 311,351.9 				" />
                    <polygon class="st6" points="282,352.9 283.5,350.4 296.7,350.4 296.7,352.9 				" />
                    <path class="st6" d="M298.7,356.7c4.1-1.8,4.3-4.5,4.3-4.5v-0.9h3.1v1.4c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.6-0.4,1.1-0.6,1.6
					c-0.3,0.6-0.7,1.2-1.2,1.7c-0.6,0.6-1.2,1.1-2.1,1.6c-0.6,0.4-1.2,0.6-1.8,0.9h0c0,0-0.4,0.2-0.6,0.2l-0.3,0.1
					c0,0-0.4,0.1-0.5,0.1L298.7,356.7L298.7,356.7L298.7,356.7z" />
                    <polygon class="st6" points="280.4,364.2 281.9,361.6 318.5,361.6 318.5,364.2 				" />
                    <rect x="297" y="360.5" class="st6" width="4.7" height="11.6" />
                    <polygon class="st6" points="282.1,372.1 286.2,365.9 290.7,365.9 286.7,372.1 				" />
                    <polygon class="st6" points="312.9,372.1 308.8,365.9 313.5,365.9 317.5,372.1 				" />
                    <polygon class="st6" points="298.6,353.7 302.2,353.7 307.3,359.7 303.7,359.7 				" />
                    <polygon class="st6" points="282.6,357.2 296.7,355.4 296.7,358.3 282.6,360 				" />
                    <polygon class="st6" points="287.9,357.6 287.9,351.2 291.9,352 291.9,357.6 				" />
                    <rect x="288.2" y="352" class="st6" width="3.7" height="4.9" />
                    <rect x="281.6" y="340.7" class="st6" width="4.5" height="7.8" />
                    <rect x="281.9" y="340.7" class="st6" width="3.7" height="7.1" />
                    <rect x="289.9" y="343.7" class="st6" width="4" height="4.8" />
                    <rect x="290.1" y="343.7" class="st6" width="3.7" height="4.3" />
                    <rect x="301.2" y="340.7" class="st6" width="4" height="7.8" />
                    <rect x="301.4" y="340.7" class="st6" width="3.7" height="7.1" />
                    <rect x="309.4" y="343.7" class="st6" width="4" height="4.8" />
                    <rect x="309.7" y="343.7" class="st6" width="3.7" height="4.3" />
                    <polygon class="st6" points="284,345.1 285.5,342.6 299.1,342.6 299.1,345.1 				" />
                    <polygon class="st6" points="302.5,345.1 304,342.6 317.6,342.6 317.6,345.1 				" />
                </g>
                <g>
                    <g>
                        <rect x="245.2" y="343.8" class="st6" width="2.1" height="9.1" />
                        <rect x="244.6" y="344.2" class="st6" width="3.8" height="10.4" />
                        <path class="st6"
                            d="M230.5,353.9v-1.1H246v-9.1h-15.4v-1.4h16.4c0.8,0,1.5,0.6,1.5,1.5v10.1L230.5,353.9L230.5,353.9z" />
                        <polygon class="st6" points="215.9,344.3 216.9,342.5 224.3,342.5 225.2,344.3 					" />
                        <polygon class="st6" points="218,353.9 219.1,352.1 224.5,352.1 224.5,353.9 					" />
                        <path class="st6"
                            d="M224,343.9h-5.3v-1.4h6.2c0.7,0,1.4,0.6,1.5,1.3v0.3l-2.4,3.7L224,343.9L224,343.9L224,343.9z" />
                        <polygon class="st6" points="219.1,352.7 219,352.2 224.1,344.4 226.2,344.4 220.9,352.7 					" />
                        <polygon class="st6" points="215.3,353.9 216.5,352.1 221.9,352.1 221.9,353.9 					" />
                        <polygon class="st6"
                            points="216.5,352.7 216.4,352.2 221.8,343.7 224.9,343.7 219.2,352.7 					" />
                        <path class="st6"
                            d="M224.9,353.5h-5.3v-1.4h6.2c0.7,0,1.4,0.6,1.5,1.3v0.2l-2.4,3.4L224.9,353.5L224.9,353.5z" />
                        <rect x="236.4" y="340.7" class="st6" width="3.8" height="26" />
                        <path class="st6" d="M214.6,368.4l0.6-0.2c0.3-0.1,7.2-3.1,8.1-15.1l3.4,0.3c0,0,0.5,0.6,0.6,0.6
						c-1.3,14.6-10.4,18.2-10.9,18.4h-0.2v0.1c-0.3,0.1-0.9,0.4-1.6,0.6L214.6,368.4L214.6,368.4L214.6,368.4z" />
                        <polygon class="st6" points="228,344.9 229.3,342.3 246.7,342.3 246.7,344.9 					" />
                        <polygon class="st6" points="228.7,354.6 230,352 247.5,352 247.5,354.6 					" />
                        <polygon class="st6" points="228.7,359.4 230,356.8 248.9,356.8 248.9,359.4 					" />
                        <polygon class="st6" points="226.8,364.2 228.2,361.7 250.7,361.7 250.7,364.2 					" />
                        <polygon class="st6" points="226.8,349.8 228.2,347.2 250.7,347.2 250.7,349.8 					" />
                    </g>
                    <path class="st6" d="M230.2,367.9c-4.9-0.6-6.9-3.5-7-3.7l-0.1-0.1c-1.5-2-3.2-6.2-3.9-7.9h-4.1c0,0,0.2,0.4,0.2,0.6
					c0.9,2.5,2.8,6.9,4.6,9.5c0.6,0.8,3.7,5,10.4,5.7h20.4v-4L230.2,367.9L230.2,367.9z" />
                </g>
            </g>
        </g>
    </g>
</svg>