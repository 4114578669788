// local-only.guard.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LocalOnlyGuard {
    constructor(private router: Router) { }

    canActivate(): boolean {
        const isLocalhost = window.location.hostname === 'localhost';

        if (!isLocalhost) {
            // 如果不是在 localhost，導航到一個錯誤頁面或其他適當的處理方式
            this.router.navigate(['/error']);
            return false;
        }

        // 允許導航
        return true;
    }
}
