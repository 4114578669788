import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class KeyVisualDataService {

  private RtPath$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private navLinePos$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private pageType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private kvTitle_CH$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private kvTitle_EN$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private caseName$: BehaviorSubject<string> = new BehaviorSubject<string>('');





  //頁面型態
  public setPageType(pageType: string): void {
    this.pageType$.next(pageType);
  }

  public getPageType(): Observable<string> {
    return this.pageType$.asObservable();
  }


  //路徑
  public setRtPath(RtPath: string): void {
    this.RtPath$.next(RtPath);
  }

  public getRtPath(): Observable<string> {
    return this.RtPath$.asObservable();

  }

  //導航列所在位置
  public setNavLinePos(navLinePos: number): void {
    this.navLinePos$.next(navLinePos);
  }

  public getNavLinePos(): Observable<number> {
    return this.navLinePos$.asObservable();
  }


  //中文標題
  public setkvTitle_CH(kvTitle_CH: string): void {
    this.kvTitle_CH$.next(kvTitle_CH);
  }

  public getkvTitle_CH(): Observable<string> {
    return this.kvTitle_CH$.asObservable();
  }

  //英文標題
  public setkvTitle_EN(kvTitle_EN: string): void {
    this.kvTitle_EN$.next(kvTitle_EN);
  }

  public getkvTitle_EN(): Observable<string> {
    return this.kvTitle_EN$.asObservable();
  }

  //作品名稱
  public setCaseName(caseName: string): void {
    this.caseName$.next(caseName);
  }

  public getCaseName(): Observable<string> {
    return this.caseName$.asObservable();
  }


  constructor(

  ) {

  }



}
